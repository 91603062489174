import React, { useState, useEffect, Fragment } from "react";
import Select from "react-select";
import XLSX from "xlsx";
import ImgCard from "./ImageCard";
import { jsPDF } from "jspdf";
import Resizer from "react-image-file-resizer";
import "./App.css";
import "./PlanoTipo.css";
import waLogo from "../imgs/WhatsAppLogo.png";
import pdfLogo from "../imgs/Icon_PDF.png";
import ReactGA from "react-ga4";
import SendMetric from "./SendMetric";
import TimeMe from "timeme.js";
import MyLoading from "./IterateEllipses";
import { Buffer } from "buffer";
const unitParam = "CODIGO INMUEBLE"; //CODIGO INMUEBLE //AGRUPACION //La columna de la ficha técnica que se utiliza para el dropdown de Unidad.

//#region MY STYLES
const myStyles = {
  myDiv: {
    display: "block",
    marginTop: 10,
    padding: 20,
    backgroundColor: "#282c34",
  },
  myBtn: {
    fontSize: 30,
    fontWeight: "bold",
    color: "black",
    cursor: "pointer",
    padding: "20px",
  },
  myText: {
    fontSize: 18,
    fontWeight: "bold",
    color: "black",
    margin: "auto",
    padding: "10px 20px 10px 20px",
  },
  myText2: {
    fontSize: 16,
    //fontWeight: "bold",
    color: "black",
    textAlign: "center",
    paddingLeft: "10px",
    lineHeight: "25px",
  },
  myImg: {
    boxShadow: "0 0 20px 2px #9b9b9b",
    borderRadius: "10px",
    width: "100%",
    height: "auto",
    maxWidth: "500px",
  },
};

const dropdownStyle = {
  option: (provided, state) => ({
    ...provided,
    //borderBottom: "1px dashed #008c45",
    backgroundColor: state.isDisabled
      ? undefined
      : state.isSelected
      ? "#008c45"
      : state.isFocused
      ? "#008c45"
      : undefined,
    color: state.isDisabled
      ? undefined
      : state.isSelected
      ? "black"
      : state.isFocused
      ? "white"
      : undefined,
    //paddingLeft: "200px",
    //padding: 20,
    //padding: 20,
    cursor: "pointer",
  }),
  control: (provided) => ({
    ...provided,
    //width: 200,
    height: 40,
    boxShadow: "0px 3px 5px #a7a7a7",
    color: "#808080",
    paddingLeft: "60px",
    cursor: "pointer",
    borderRadius: "20px",
    borderStyle: "none",
    border: "2px",
    borderColor: "#008c45",
    "&:hover": {
      borderStyle: "solid",
      borderColor: "#008c45",
      color: "red",
    },
    "@media (max-width: 600px)": {
      paddingLeft: "0px",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    //const opacity = state.isDisabled ? 0.5 : 1;
    //const transition = "opacity 300ms";
    //color: "black",

    //return { ...provided, opacity, transition };
  }),
  menu: (provided, state) => ({
    ...provided,
    color: "#808080",
  }),
};
//#endregion

function PlanoTipo(user) {
  //#region TIME ME
  TimeMe.initialize({
    currentPageName: "my-home-page", // current page
    idleTimeoutInSeconds: 90, // seconds
    // trackWhenUserLeavesPage: false,
    // trackWhenUserGoesIdle: false,
  });
  //#endregion

  //#region VARS
  const client = "CBC";

  //VARS UTILITIES
  const [showClg, setShowClg] = useState(false);
  const [soporteTecnico, setSoporteTecnico] = useState("");

  //VARS BIM AUTH
  const [accessToken, setAccessToken] = useState({
    myToken: "",
    expire: "",
    timeStamp: 0,
  });

  //VARS PROYECTO
  const [warningProyectos, setWarningProyectos] = useState("");
  const [projectsList, setProjectsList] = useState([]);
  const [showDropdownProyectos, setShowDropdownProyectos] = useState(false);
  const [dropdownProyectosOptions, setDropdownProyectosOptions] = useState([]);
  const [plantasList, setPlantasList] = useState([]);
  const [selectedProject, setSelectedProject] = useState({});

  //VARS FICHA TECNICA - UNIDADES
  const [warningUnits, setWarningUnits] = useState("");
  const [errorUnits, setErrorUnits] = useState("");
  const [unitsList, setUnitsList] = useState([]);
  const [showDropdownUnidades, setShowDropdownUnidades] = useState(false);
  const [dropdownUnidadesOptions, setDropdownUnidadesOptions] = useState([]);

  //VARS MULTI FICHA DROPDOWN
  const [showMultiFichas, setShowMultiFichas] = useState(false);
  const [multiFichasOptions, setMultiFichasOptions] = useState([]);
  const [warnMultiFichas, setWarnMultiFichas] = useState("");
  const [selectedFicha, setSelectedFicha] = useState("");
  const [showFichaFound, setShowFichaFound] = useState(false);

  //VARS SELECTED UNIT
  const [imageList, setImageList] = useState("");
  const [showImages, setShowImages] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [galleryImage, setGalleryImage] = useState("");
  const [selectedUnit, setSelectedUnit] = useState({});

  //VAR JSPDF
  const [showPdfExportBtn, setShowPdfExportBtn] = useState(false);
  const [showLoadingExportBtn, setShowLoadingExportBtn] = useState(false);
  const [sendMetric, setSendMetric] = useState(true);
  //#endregion

  //#region UTILS
  const toggleConsoleLogs = () => {
    setShowClg(!showClg);
  };

  const formatMessage = (myMessage) => {
    let newText = myMessage.split("\n").map((i) => {
      return (
        <span style={myStyles.myText2} key={i}>
          {i}
          <br />
        </span>
      );
    });
    return newText;
  };

  const resetStates = () => {
    setImageList("");
    setWarningUnits("");
    setErrorUnits("");
    setShowImages(false);
    setSelectedImages([]);
    setGalleryImage("");
    setShowPdfExportBtn(false);
    setSoporteTecnico("");
    setWarnMultiFichas("");
    setSendMetric(true);
  };

  const whatsappClickToChat = () => {
    var showWarningProyectos = false;
    if (warningProyectos !== "") {
      if (soporteTecnico === warningProyectos) showWarningProyectos = false;
      else showWarningProyectos = true;
    }

    var errorMsg =
      soporteTecnico === ""
        ? "Error: N/A"
        : `Error: ${
            showWarningProyectos ? `${warningProyectos[0].key} %0a ` : ""
          }${soporteTecnico} `;

    errorMsg = errorMsg.replaceAll("•", "%0a •");
    var myFicha =
      selectedFicha === "" ? "" : ` %0a Ficha Técnica: ${selectedFicha}`;
    var myProject =
      selectedProject === undefined ? "" : `Proyecto: ${selectedProject.name}`;
    var tempMessage = ` Hola, mi nombre es ${user.userData.name}. Necesito soporte técnico para la herramienta Plano Tipo. %0a ${myProject}${myFicha} %0a ${errorMsg}`;
    window.open(
      `https://wa.me/${process.env.REACT_APP_WHATSAPP_SOPORTE}?text=${tempMessage}`,
      "_blank"
    );
  };

  const isValidToken = async () => {
    // Catch Error: TypeError: Cannot read properties of undefined (reading '0')
    var cTs = Math.floor(Date.now() / 1000);
    // console.log(
    //   accessToken.expire +
    //     " | " +
    //     accessToken.timeStamp +
    //     "=" +
    //     (accessToken.expire + accessToken.timeStamp) +
    //     " | " +
    //     cTs
    // );
    if (cTs < accessToken.timeStamp + accessToken.expire) {
      return accessToken.myToken;
    } else {
      /* #region Request Params */
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      //New Auth V2 --------------------------------------------
      const encondedAuth = Buffer.from(
        `${process.env.REACT_APP_FORGE_CLIENT_ID}:${process.env.REACT_APP_FORGE_CLIENT_SECRET}`
      ).toString("base64");
      //console.log(encondedAuth);
      myHeaders.append("Authorization", `Basic ${encondedAuth}`);

      var urlencoded = new URLSearchParams();
      // urlencoded.append("client_id", process.env.REACT_APP_FORGE_CLIENT_ID);
      // urlencoded.append(
      //   "client_secret",
      //   process.env.REACT_APP_FORGE_CLIENT_SECRET
      // );
      urlencoded.append("grant_type", "client_credentials");
      urlencoded.append("scope", "data:read");
      urlencoded.append("scope", "data:search");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };
      /* #endregion */
      /* #region ASYNC METHOD */
      try {
        const response = await fetch(
          //"https://developer.api.autodesk.com/authentication/v1/authenticate",
          "https://developer.api.autodesk.com/authentication/v2/token",
          requestOptions
        );
        const json = await response.json();
        setAccessToken({
          myToken: json.access_token,
          expire: json.expires_in,
          timeStamp: Math.floor(Date.now() / 1000),
        });
        console.log("New Token");
        return json.access_token;
      } catch (error) {
        console.error("Catch Error: ", error);
        setWarningProyectos(formatMessage(`Catch Error: ${error}`));
        setSoporteTecnico(`Catch Error: ${error}`);
      }
      /* #endregion */
    }
  };
  //#endregion

  //#region 01 - GET PROJECTS LIST ---------------------------------------------------------------------------------------------------------
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    ReactGA.send({ hitType: "pageview", page: "/PlanoTipo" });
    //console.log(userData.name);
    //#region BIM360 - AUTHENTICATE & GET ACCESS TOKEN
    const bimAuthGetAccessToken = async () => {
      /* #region Request Params */
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      //New Auth V2 --------------------------------------------
      const encondedAuth = Buffer.from(
        `${process.env.REACT_APP_FORGE_CLIENT_ID}:${process.env.REACT_APP_FORGE_CLIENT_SECRET}`
      ).toString("base64");
      //console.log(encondedAuth);
      myHeaders.append("Authorization", `Basic ${encondedAuth}`);

      var urlencoded = new URLSearchParams();
      // urlencoded.append("client_id", process.env.REACT_APP_FORGE_CLIENT_ID);
      // urlencoded.append(
      //   "client_secret",
      //   process.env.REACT_APP_FORGE_CLIENT_SECRET
      // );
      urlencoded.append("grant_type", "client_credentials");
      urlencoded.append("scope", "data:read");
      urlencoded.append("scope", "data:search");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };
      /* #endregion */

      /* #region ASYNC METHOD */
      try {
        const response = await fetch(
          //"https://developer.api.autodesk.com/authentication/v1/authenticate",
          "https://developer.api.autodesk.com/authentication/v2/token",
          requestOptions
        );
        const json = await response.json();
        //console.log(json);
        setAccessToken({
          myToken: json.access_token,
          expire: json.expires_in,
          timeStamp: Math.floor(Date.now() / 1000),
        });
        // if (showClg) console.log("MyToken: \n" + json.access_token);
        bimGetHub(json.access_token);
      } catch (error) {
        console.error("Catch Error: ", error);
        setWarningProyectos(formatMessage(`Catch Error: ${error}`));
        setSoporteTecnico(`Catch Error - Autenticando: ${error}`);
      }
      /* #endregion */
    };
    //#endregion
    //#region BIM360 - GET HUB
    const bimGetHub = (myToken) => {
      /* #region  Request Params */
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + myToken);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
      };
      /* #endregion */

      /* #region  THEN METHOD */
      fetch(
        "https://developer.api.autodesk.com/project/v1/hubs",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log("Hubs: \n" + JSON.stringify(data, null, 4));
          // if (showClg) console.log("Hub id: \n" + data.data[0].id);
          bimGetProjectsList(data.data[0].id, requestOptions);
        })
        .catch((error) => {
          console.error("Catch Error: ", error);
          setWarningProyectos(formatMessage(`Catch Error: ${error}`));
          setSoporteTecnico(`Catch Error - Obteniendo Hubs: ${error}`);
        });
      /* #endregion */
    };
    //#endregion
    //#region BIM360 - GET PROJECT LIST
    const bimGetProjectsList = async (myHubId, requestOptions) => {
      try {
        const response = await fetch(
          `https://developer.api.autodesk.com/project/v1/hubs/${myHubId}/projects`,
          requestOptions
        );
        const json = await response.json();

        var myProjectsList = [];
        var myDropdownProyectos = [];
        for (let i = 0; i < json.data.length; i++) {
          //Only add Projects not incluiding substring...
          if (
            !json.data[i].attributes.name.includes("00-") &&
            !json.data[i].attributes.name.includes("TEST") &&
            !json.data[i].attributes.name.includes("Component Library") &&
            !json.data[i].attributes.name.includes("-URB_")
          ) {
            const element = {
              name: json.data[i].attributes.name,
              id: json.data[i].id,
              rootFolder: json.data[i].relationships.rootFolder.data.id,
            };
            const itemDropdown = {
              value: json.data[i].attributes.name,
              label: json.data[i].attributes.name,
            };
            //console.log(element);
            myProjectsList.push(element);
            myDropdownProyectos.push(itemDropdown);
          }
        }
        myProjectsList.sort((a, b) => a.name.localeCompare(b.name));
        myDropdownProyectos.sort((a, b) => a.value.localeCompare(b.value));
        // console.log(JSON.stringify(myProjectsList, null, 4));
        console.log(myProjectsList);
        //console.log(JSON.stringify(myDropdownProyectos, null, 4));
        // if (showClg) console.log("Project Count: " + myDropdownProyectos.length);

        setProjectsList(myProjectsList);
        setDropdownProyectosOptions(myDropdownProyectos);
        setShowDropdownProyectos(true);
      } catch (error) {
        console.error(`Catch Error - Obteniendo Proyectos: ${error}`);
        setWarningProyectos(`Catch Error - Obteniendo Proyectos: ${error}`);
        setSoporteTecnico(`Catch Error - Obteniendo Proyectos: ${error}`);
      }
    };
    //#endregion
    bimAuthGetAccessToken();
  }, []);
  //#endregion ------------------------------------------------------------------------------------------------------------------------------

  //#region 02 - GET FICHA TECNICA FROM SELECTED PROJECT -----------------------------------------------------------------------------------

  //#region 02-A - GET /ProjectFiles ID
  const bimNavigateToProjectFiles = async (mySelectedProject) => {
    ReactGA.send({ hitType: "pageview", page: "/" + mySelectedProject.name });

    //#region ADD AWS METRIC --------------------------------------------------------
    const myMetric = JSON.stringify({
      requestType: "newMetric",
      metric: {
        client: client,
        user: user.userData.email,
        metric: "selectedProject",
        value1: mySelectedProject.name,
        value2: "",
      },
    });
    // const metricResponse = await SendMetric(myMetric);
    SendMetric(myMetric);
    // console.log(metricResponse);
    //#endregion ADD AWS METRIC -----------------------------------------------------

    setWarningProyectos(
      formatMessage("Buscando Ficha Técnica y plantas ambientadas...")
    );

    //Validate Token
    const myToken = await isValidToken();

    /* #region  Request Params */
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + myToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    /* #endregion */

    /* #region  ASYNC METHOD */
    try {
      const response = await fetch(
        `https://developer.api.autodesk.com/data/v1/projects/${mySelectedProject.id}/folders/${mySelectedProject.rootFolder}/contents?filter[attributes.name]=Project%20Files`,
        requestOptions
      );
      const json = await response.json();
      // console.log("Project Files: \n" + JSON.stringify(json));
      if (json.data[0].id !== undefined) {
        if (showClg)
          console.log("Found Folder: " + json.data[0].attributes.name);
        bimNavigateToDocsDiseno(mySelectedProject, json.data[0].id, myToken);
      } else {
        if (showClg) console.log("No se encontró la carpeta /ProjectFiles");
        setWarningProyectos(
          formatMessage("No se encontró la carpeta /ProjectFiles")
        );
        setSoporteTecnico(`No se encontró la carpeta /ProjectFiles`);
      }
    } catch (error) {
      console.error("Catch Error: ", error);
      setWarningProyectos(
        formatMessage(
          `Catch Error - Buscando contenido en carpeta /ProjecFiles: ${error}`
        )
      );
      setSoporteTecnico(
        `Catch Error - Buscando contenido en carpeta /ProjecFiles: ${error}`
      );
    }
    /* #endregion */
  };
  //#endregion

  //#region 02-B - GET CONTENTS OF /ProjectFiles & GET ID OF /*DISEÑO
  const bimNavigateToDocsDiseno = async (
    mySelectedProject,
    folderId,
    myToken
  ) => {
    try {
      let myFolder;

      const projectFilesContent = await folderContents(
        mySelectedProject,
        folderId,
        myToken
      );

      //Search folder in BIM360
      for (let i = 0; i < projectFilesContent.data.length; i++) {
        if (projectFilesContent.data[i].attributes.name.includes("DISEÑO")) {
          myFolder = projectFilesContent.data[i];
          break;
        }
      }

      //Search folder in ACC
      if (!myFolder) {
        let docsFolderId;
        for (let i = 0; i < projectFilesContent.data.length; i++) {
          if (projectFilesContent.data[i].attributes.name.includes("DOCS")) {
            docsFolderId = projectFilesContent.data[i].id;
            break;
          }
        }

        if (docsFolderId) {
          const docsContent = await folderContents(
            mySelectedProject,
            docsFolderId,
            myToken
          );
          // console.log("docsContent:", docsContent);
          for (let i = 0; i < projectFilesContent.data.length; i++) {
            if (docsContent.data[i].attributes.name.includes("DISEÑO")) {
              myFolder = docsContent.data[i];
              break;
            }
          }
        }
      }

      //Folder found
      if (myFolder !== undefined) {
        // console.log("myFolder", myFolder);
        bimNavigateToFichaTecnica(mySelectedProject, myFolder.id, myToken);
        if (showClg) {
          console.log(
            `Found Folder:  ${myFolder.attributes.name} | ${myFolder.id}`
          );
        }
      }
      //NO Folder found
      else {
        console.log("No se encontró la carpeta /*...DISEÑO");
        setWarningProyectos(
          formatMessage("No se encontró la carpeta /*...DISEÑO")
        );
        setSoporteTecnico("No se encontró la carpeta /*...DISEÑO");
      }
    } catch (error) {
      console.error("Catch Error: ", error);
      setWarningProyectos(
        formatMessage(
          `Catch Error - Obteniendo contenido de /ProjectFiles ${error}`
        )
      );
      setSoporteTecnico(
        `Catch Error - Obteniendo contenido de /ProjectFiles ${error}`
      );
    }
    /* #endregion */
  };
  //#endregion

  //#region 02-C - GET CONTENTS OF /*DISEÑO & GET ID OF /*FICHA & GET ID OF /*AMBIENTACIONES
  const bimNavigateToFichaTecnica = async (
    mySelectedProject,
    folderId,
    myToken
  ) => {
    /* #region  Request Params */
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + myToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    /* #endregion */

    /* #region  ASYNC METHOD */
    try {
      const response = await fetch(
        `https://developer.api.autodesk.com/data/v1/projects/${mySelectedProject.id}/folders/${folderId}/contents`, //?filter[attributes.name]=D3-FICHA%20TÉCNICA`,
        requestOptions
      );
      const json = await response.json();
      //console.log("CONTENTS DISEÑO: \n" + JSON.stringify(json));

      var myFolderFicha;
      var myFolderAmbientaciones;
      var strWarning = "";
      for (let i = 0; i < json.data.length; i++) {
        //#region Get ID of /*AMBIENTACIONES
        if (myFolderAmbientaciones === undefined) {
          if (
            json.data[i].attributes.name
              .toLowerCase()
              .includes("ambientaciones")
          ) {
            myFolderAmbientaciones = json.data[i];
            if (showClg) {
              console.log(
                `Found Folder: ${myFolderAmbientaciones.attributes.name} | ${myFolderAmbientaciones.id}`
              );
            }
          }
        }
        //#endregion

        //#region Get /*FICHA object
        if (myFolderFicha === undefined) {
          if (
            json.data[i].attributes.name.toLowerCase().includes("ficha") &&
            json.data[i].attributes.name.toLowerCase().includes("d3")
          ) {
            myFolderFicha = json.data[i];
            if (showClg) {
              console.log(
                `Found Folder: ${myFolderFicha.attributes.name} | ${myFolderFicha.id}`
              );
            }
          }
        }
        //#endregion
      }

      //#region Show Warnings and Return on Error
      if (myFolderAmbientaciones === "") {
        strWarning =
          "No se encontró una carpeta de 'Ambientaciones' en BIM 360.";
      }
      if (myFolderFicha === undefined) {
        strWarning =
          strWarning === ""
            ? "No se encontró una carpeta de 'Ficha Técnica' en BIM 360."
            : strWarning +
              "\nNo se encontró una carpeta de 'Ficha Técnica' en BIM 360.";
      }
      if (strWarning !== "") {
        setWarningProyectos(formatMessage(strWarning));
        setSoporteTecnico(strWarning);
        return;
      }
      //#endregion

      //#region Process Ambientaciones & Ficha Tecnica
      var ambientaciones = await bimContentsOfAmbientaciones(
        mySelectedProject,
        myFolderAmbientaciones.id,
        myFolderAmbientaciones.attributes.name,
        myToken
      );
      var fichaContents = await bimContentsOfFichaTecnica(
        mySelectedProject,
        myFolderFicha.id,
        myFolderFicha.attributes.name,
        myToken
      );

      var showingSoporte = false;
      if (ambientaciones !== "" && fichaContents !== "")
        fichaContents = `\n${fichaContents}`;
      if (
        ambientaciones !== "" ||
        (fichaContents !== "" && !fichaContents.includes("múltiples"))
      ) {
        setWarningProyectos(formatMessage(`${ambientaciones}${fichaContents}`));
        setSoporteTecnico(`${ambientaciones} \n ${fichaContents}`);
        showingSoporte = true;
      }

      if (fichaContents.includes("múltiples") && !showingSoporte) {
        setShowMultiFichas(true);
      }

      //#endregion
    } catch (error) {
      console.error("Catch Error: ", error);
      setWarningProyectos(
        formatMessage(
          `Catch Error - Obteniendo contenido de /*...DISEÑO: ${error}`
        )
      );
      setSoporteTecnico(
        `Catch Error - Obteniendo contenido de /*...DISEÑO: ${error}`
      );
    }
    /* #endregion */
  };
  //#endregion

  //#region 02-D1 - GET CONTENTS OF /*AMBIENTACIONES & FIND .JPGs
  const bimContentsOfAmbientaciones = async (
    mySelectedProject,
    folderId,
    folderName,
    myToken
  ) => {
    /* #region  Request Params */
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + myToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    /* #endregion */

    /* #region  ASYNC METHOD */
    try {
      /* #region FETCH */
      const response = await fetch(
        `https://developer.api.autodesk.com/data/v1/projects/${mySelectedProject.id}/folders/${folderId}/contents`,
        requestOptions
      );
      const json = await response.json();
      //console.log("CONTENTS of /*AMBIENTACIONES: \n" + JSON.stringify(json));
      /* #endregion */

      //#region Warning if Data = 0 & return
      if (json.data.length === 0) {
        if (showClg) console.log(folderName + " data: " + json.data.length);
        return `No se encontraron archivos en la carpeta ${folderName}.`;
      }
      //#endregion

      //#region Look for folder & Get contents of /*...PLANTAS
      else {
        var myPlantasFolder = "";
        for (let i = 0; i < json.data.length; i++) {
          if (
            json.data[i].attributes.displayName
              .toLowerCase()
              .includes("plantas")
          ) {
            myPlantasFolder = json.data[i].attributes.displayName;
            if (showClg) {
              console.log(
                `Found Folder: ${json.data[i].attributes.displayName} | ${json.data[i].id}`
              );
            }
            /* #region  ASYNC METHOD - GET CONTENTS of /*PLANTAS */
            try {
              /* #region FETCH */
              const response2 = await fetch(
                `https://developer.api.autodesk.com/data/v1/projects/${mySelectedProject.id}/folders/${json.data[i].id}/contents`,
                requestOptions
              );
              const json2 = await response2.json();
              //console.log("/*PLANTAS: \n" + JSON.stringify(json2, null, 4));
              var plantasObjs = [];
              if (json2.included) {
                for (let i = 0; i < json2.included.length; i++) {
                  //for (let i = 0; i < json2.data.length; i++) {
                  try {
                    if (
                      json2.included[i].attributes.displayName.includes("jpg")
                      //json2.data[i].attributes.displayName.includes("jpg")
                    ) {
                      //console.log(json2.data[i].attributes.displayName);
                      const myObj = {
                        name: json2.included[i].attributes.displayName,
                        id: json2.included[i].id,
                        image: ["bla"],
                        storageId:
                          json2.included[i].relationships.storage.data.id,
                        // name: json2.data[i].attributes.displayName,
                        // id: json2.data[i].id,
                        // image: ["bla"],
                        // storageId: json2.data[i].relationships.storage.data.id,
                      };
                      plantasObjs.push(myObj);
                    }
                  } catch (error) {
                    console.error("Catch Error: ", error);
                    return (
                      `Catch Error - Obteniendo data de ${json2.included[i].attributes.displayName}: ` +
                      error
                    );
                  }
                }
              }
              //console.log("Plantas: \n" + JSON.stringify(plantasObjs, null, 4));
              if (plantasObjs.length > 0) {
                setPlantasList(plantasObjs);
                return "";
              } else {
                return `No se encontraron imágenes JPG en la carpeta ${myPlantasFolder}.`;
              }
              /* #endregion */
            } catch (error) {
              console.error(
                `Catch Error - Obteniendo contenido de /${myPlantasFolder}: ${error}`
              );
              return `Catch Error - Obteniendo contenido de /${myPlantasFolder}: ${error}`;
            }
            /* #endregion */
          }
        }
        //No encontró folder /*...PLANTAS
        if (myPlantasFolder === "") {
          return `No se encontró la carpeta /*...PLANTAS`;
        }
      }
      //#endregion
    } catch (error) {
      console.error("Catch Error: ", error);
      return `Catch Error - Obteniendo contenido de /${folderName}: ` + error;
    }
    /* #endregion */
  };
  //#endregion

  //#region 02-D2 - GET CONTENTS OF /*FICHA & FIND *ficha*.xlsx
  const bimContentsOfFichaTecnica = async (
    mySelectedProject,
    folderId,
    folderName,
    myToken
  ) => {
    /* #region  Request Params */
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + myToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    /* #endregion */

    /* #region  ASYNC METHOD */
    try {
      //#region FETCH
      const response = await fetch(
        `https://developer.api.autodesk.com/data/v1/projects/${mySelectedProject.id}/folders/${folderId}/contents`,
        requestOptions
      );
      const json = await response.json();
      if (showClg) console.log("fichaTecnicaContents: ", json);
      //#endregion

      //#region Find all XLSX files
      var myFilesWithXlsX = [];
      var searchExtesionFiles = ".xlsx"; //.csv
      //console.log(json);
      if (json.data.length > 0) {
        for (let i = 0; i < json.data.length; i++) {
          if (json.data[i].type === "folders") json.data.splice(i, 1); //Removes de object from the array if its type is folder.
        }
        if (showClg) console.log(json);
        for (let i = 0; i < json.data.length; i++) {
          if (
            json.data[i].attributes.displayName
              .toLowerCase()
              .includes(searchExtesionFiles)
          ) {
            if (showClg) {
              console.log(
                "Display Name: " + json.data[i].attributes.displayName
              );
            }
            var myJsonObj = json.data[i];
            myJsonObj.url = json.included[i].relationships.storage.data.id;
            //myFilesWithXlsX.push(json.data[i]);
            myFilesWithXlsX.push(myJsonObj);
            //console.log(myJsonObj);
          }
        }
      } else {
        return `No se encontraron archivos en la carpeta ${folderName}`;
      }

      //   console.log(
      //     "EXCELS: " + myXlsx.length + "\n" + JSON.stringify(myXlsx, null, 4)
      //   );
      //#endregion

      //#region Select Ficha Tecnica
      var myFichas = [];
      var myFicha = [];
      if (myFilesWithXlsX.length > 1) {
        //#region OLD - AUTO FIND & SELECT FICHA - DEPRICATED
        /*
        //#region Find Files with "Ficha" in name
        for (let i = 0; i < myFilesWithXlsX.length; i++) {
          if (
            myFilesWithXlsX[i].attributes.displayName
              .toLowerCase()
              .includes("ficha")
          ) {
            myFichas.push(myFilesWithXlsX[i]);
          }
        }
        //#endregion

        //#region Select Ficha by lastModifiedTime
        if (myFichas.length === 0) {
          setWarningProyectos(formatMessage("Ficha técnica not found."));
          return;
        } else {
          if (myFichas.length > 0) {
            myFichas.sort((a, b) => {
              return (
                new Date(a.attributes.lastModifiedTime) -
                new Date(b.attributes.lastModifiedTime)
              );
            });
            for (let i = 0; i < myFichas.length; i++) {
              console.log(
                `Found Ficha[${i}]: ${myFichas[i].attributes.displayName} | ${myFichas[i].attributes.lastModifiedTime}`
              );
            }
            myFicha.push(myFichas[myFichas.length - 1]);
          } else {
            myFicha = myFichas;
          }
        }
        //#endregion
        */
        //#endregion

        //#region NEW - SHOW MULTI FICHA DROPDOWN
        for (let i = 0; i < myFilesWithXlsX.length; i++) {
          const item = {
            value: myFilesWithXlsX[i].attributes.displayName,
            label: myFilesWithXlsX[i].attributes.displayName,
            id: myFilesWithXlsX[i].id,
            storageId: myFilesWithXlsX[i].url,
          };
          myFichas.push(item);
        }
        setWarningProyectos(
          formatMessage(`Se encontraron múltiples fichas técnicas.`)
        );
        setMultiFichasOptions(myFichas);
        return `Se encontraron múltiples fichas técnicas.`;
        //#endregion
      } else if (myFilesWithXlsX.length === 1) {
        //#region SE ENCUENTRA 1 SOLA FICHA
        myFicha[0] = myFilesWithXlsX[0];
        if (showClg) {
          console.log(
            `Found Ficha: ${myFicha[0].attributes.displayName} | ${myFicha[0].attributes.lastModifiedTime}`
          );
        }
        setSelectedFicha(myFicha[0].attributes.displayName);
        setShowFichaFound(true);
        setWarningProyectos("");
        if (showClg) {
          console.log(
            "Ficha Técnica: " +
              myFicha[0].attributes.displayName +
              " | " +
              myFicha[0].attributes.lastModifiedTime +
              " | " +
              myFicha[0].id +
              " | " +
              myFicha[0].url
          );
        }
        bimHrefFichaTecnica(mySelectedProject, myFicha[0].id, myToken);
        setWarningUnits("Cargando ficha técnica...");
        return "";
        //#endregion
      } else {
        return `No se encontraron archivos ${searchExtesionFiles.toUpperCase()} en la carpeta ${folderName}.`;
      }
      //#endregion
    } catch (error) {
      console.error("Catch Error: ", error);
      return `Catch Error - Obteniendo contenido de ${folderName}: ${error}`;
    }
    /* #endregion */
  };
  //#endregion

  //#region 02-E - GET HREF OF *ficha*.xlsx
  const bimHrefFichaTecnica = async (mySelectedProject, myFichaId) => {
    //Validate Token
    const myToken = await isValidToken();
    //console.log(mySelectedProject);

    /* #region  Request Params */
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + myToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    /* #endregion */

    /* #region  ASYNC METHOD */
    try {
      const response = await fetch(
        `https://developer.api.autodesk.com/data/v1/projects/${mySelectedProject.id}/items/${myFichaId}`,
        requestOptions
      );
      const json = await response.json();
      if (showClg) {
        console.log(
          "Ficha Técnica Endpoint: \n" +
            json.included[0].relationships.storage.meta.link.href
        );
      }
      setSelectedFicha(json.included[0].attributes.displayName);
      bimDownloadFichaTecnica(
        json.included[0].relationships.storage.meta.link.href,
        myToken,
        json.included[0].attributes.displayName,
        json.included[0].relationships.storage.data.id
      );
    } catch (error) {
      console.error("Catch Error: ", error);
      setWarningProyectos(
        formatMessage(
          `Catch Error - Obteniendo Href de Ficha Técnica: ${error}`
        )
      );
      setSoporteTecnico(
        `Catch Error - Obteniendo Href de Ficha Técnica: ${error}`
      );
    }
    /* #endregion */
  };
  //#endregion

  //#region 02-F - DOWNLOAD *ficha*.xlsx
  const bimDownloadFichaTecnica = async (
    myEndpoint,
    myToken,
    myFichaName,
    myStorageId
  ) => {
    //console.log(myStorageId);
    /* #region  Request Params */
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + myToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    /* #endregion */

    //#region GENERATE SIGNED S3 URL
    //Se agrega myStorageId por que Autodesk cambió el API y ahora se debe generar una URL para descargar desde un bucket en AWS S3
    if (myStorageId !== undefined || myStorageId !== null) {
      myEndpoint = await Utils_GenerateS3URL(myStorageId, myToken);
      if (myEndpoint !== null) {
        //console.log(myEndpoint);
        requestOptions = {};
        requestOptions = { method: "GET" };
      } else {
        return;
      }
    }
    //#endregion

    /* #region  ASYNC METHOD */
    try {
      const response = await fetch(`${myEndpoint}`, requestOptions);
      let myArrayBuffer = await response.arrayBuffer();
      let workbook = XLSX.read(myArrayBuffer);
      var sheetsNames = workbook.SheetNames;
      var sheeetNamesParsed = sheetsNames.map((x) =>
        x.replace(/\s+/g, "").toLowerCase()
      );
      if (showClg) console.log(JSON.stringify(sheeetNamesParsed, null, 4));
      const sheetIndex = sheeetNamesParsed.indexOf("planotipo");
      //console.log(sheetIndex);
      if (sheetIndex !== -1) {
        let myData = XLSX.utils.sheet_to_json(
          workbook.Sheets[sheetsNames[sheetIndex]]
        );
        if (showClg) console.log(myData);
        //console.log(myData[0]["UBICA CIÓN"]);
        //console.log(myData[0][unitParam]);

        showUnitsDropdown(myData);
        setWarnMultiFichas("");
      } else {
        setWarnMultiFichas(
          formatMessage(`El archivo ${myFichaName} no tiene hoja Plano Tipo.`)
        );
        setSoporteTecnico(
          `El archivo ${myFichaName} no tiene hoja Plano Tipo.`
        );
        setWarningUnits("");
      }
    } catch (error) {
      console.error(`Catch Error - Descargando Ficha Técnica: ${error}`);
      setWarningProyectos(
        formatMessage(`Catch Error - Descargando Ficha Técnica: ${error}`)
      );
      setSoporteTecnico(`Catch Error - Descargando Ficha Técnica: ${error}`);
    }
    /* #endregion */
  };
  //#endregion

  //#endregion ------------------------------------------------------------------------------------------------------------------------------

  //#region 03 - UNITS DROPDOWN - GET IMAGES -----------------------------------------------------------------------------------------------

  //#region 03-A - SHOW UNIT DROPDOWN
  const showUnitsDropdown = (myData) => {
    setUnitsList(myData);
    try {
      var myOptions = [];
      for (let i = 0; i < myData.length; i++) {
        const itemDropdown = {
          value: myData[i][unitParam],
          label: myData[i][unitParam],
        };
        //console.log(itemDropdown);
        if (itemDropdown.value !== "") myOptions.push(itemDropdown);
      }
      setDropdownUnidadesOptions(myOptions);
      setShowDropdownUnidades(true);
      setWarningUnits("");
    } catch (error) {
      var myError = `Catch Error - Buscando unidades en Ficha Técnica:  ${error}`;
      console.error(myError);
      setWarnMultiFichas(formatMessage(myError));
      setSoporteTecnico(myError);
    }
  };
  //#endregion

  //#region 03-B - SELECT UNIT & SHOW IMAGES LIST
  const selectUnitDropdown = async (myUnit) => {
    setWarningUnits("Descargando imágenes");
    setSelectedUnit(myUnit);
    //console.log("Plantas folder id: " + plantasFolderId);
    //console.log("Plantas: \n" + JSON.stringify(plantasList, null, 4));
    var mySelectedImages = [];

    if (showClg) console.log(myUnit);
    if (showClg) console.log(plantasList);

    //#region ARRAYS
    let macroArray = [];
    if (
      myUnit.MACRO !== undefined &&
      myUnit.MACRO !== "" &&
      myUnit.MACRO !== " "
    ) {
      macroArray = myUnit.MACRO.split(",");
    }

    let urbanoArray = [];
    if (
      myUnit.URBANO !== undefined &&
      myUnit.URBANO !== "" &&
      myUnit.URBANO !== " "
    ) {
      urbanoArray = myUnit.URBANO.split(",");
    }

    let prqArray = [];
    if (
      myUnit.PARQUEADERO !== undefined &&
      myUnit.PARQUEADERO !== "" &&
      myUnit.PARQUEADERO !== " "
    ) {
      prqArray = myUnit.PARQUEADERO.split(",");
      for (let i = 0; i < prqArray.length; i++) {
        prqArray[i] = prqArray[i].trim();
      }
    }

    let depositoArray = [];
    if (
      myUnit.DEPOSITO !== undefined &&
      myUnit.DEPOSITO !== "" &&
      myUnit.DEPOSITO !== " "
    ) {
      depositoArray = myUnit.DEPOSITO.split(",");
      depositoArray.forEach((element) => {
        prqArray.push(element);
      });
      prqArray = [...new Set(prqArray)]; //Elimina duplicados
    }

    let torreArray = [];
    if (
      myUnit.TORRE !== undefined &&
      myUnit.TORRE !== "" &&
      myUnit.TORRE !== " "
    ) {
      torreArray = myUnit.TORRE.split(",");
    }

    let planoArray = [];
    if (
      myUnit.PLANO !== undefined &&
      myUnit.PLANO !== "" &&
      myUnit.PLANO !== " "
    ) {
      planoArray = myUnit.PLANO.split(",");
    }
    //#endregion

    //#region MACRO
    let imagesNotfound = "";
    if (macroArray.length > 0) {
      for (let i = 0; i < macroArray.length; i++) {
        const element = macroArray[i];
        const elementTrim = element.trim(); //Trims whitespace from start and end
        let match;
        for (let j = 0; j < plantasList.length; j++) {
          const item = plantasList[j];
          if (item.name.substring(0, item.name.indexOf(".")) === elementTrim) {
            match = item;
            if (!mySelectedImages.includes(item)) mySelectedImages.push(item);
            break;
          }
        }
        if (match === undefined) {
          imagesNotfound = `${imagesNotfound}\n• ${elementTrim}`;
        }
        if (showClg) console.log(match);
      }
    }
    //#endregion
    //#region URBANO
    if (urbanoArray.length > 0) {
      for (let i = 0; i < urbanoArray.length; i++) {
        const element = urbanoArray[i];
        const elementTrim = element.trim(); //Trims whitespace from start and end
        let match;
        for (let j = 0; j < plantasList.length; j++) {
          const item = plantasList[j];
          if (item.name.substring(0, item.name.indexOf(".")) === elementTrim) {
            match = item;
            if (!mySelectedImages.includes(item)) mySelectedImages.push(item);
            break;
          }
        }
        if (match === undefined) {
          imagesNotfound = `${imagesNotfound}\n• ${elementTrim}`;
        }
        if (showClg) console.log(match);
      }
    }
    //#endregion
    //#region PARQUEADERO
    if (prqArray.length > 0) {
      for (let i = 0; i < prqArray.length; i++) {
        const element = prqArray[i];
        const elementTrim = element.trim(); //Trims whitespace from start and end
        let match;
        for (let j = 0; j < plantasList.length; j++) {
          const item = plantasList[j];
          if (item.name.substring(0, item.name.indexOf(".")) === elementTrim) {
            match = item;
            if (!mySelectedImages.includes(item)) mySelectedImages.push(item);
            break;
          }
        }
        if (match === undefined) {
          imagesNotfound = `${imagesNotfound}\n• ${elementTrim}`;
        }
        if (showClg) console.log(match);
      }
    }
    //#endregion
    //#region TORRE
    if (torreArray.length > 0) {
      for (let i = 0; i < torreArray.length; i++) {
        const element = torreArray[i];
        const elementTrim = element.trim(); //Trims whitespace from start and end
        let match;
        for (let j = 0; j < plantasList.length; j++) {
          const item = plantasList[j];
          if (item.name.substring(0, item.name.indexOf(".")) === elementTrim) {
            match = item;
            if (!mySelectedImages.includes(item)) mySelectedImages.push(item);
            break;
          }
        }
        if (match === undefined) {
          imagesNotfound = `${imagesNotfound}\n• ${elementTrim}`;
        }
        if (showClg) console.log(match);
      }
    }
    //#endregion
    //#region PLANO
    if (planoArray.length > 0) {
      for (let i = 0; i < planoArray.length; i++) {
        const element = planoArray[i];
        const elementTrim = element.trim(); //Trims whitespace from start and end
        let match = undefined;
        for (let j = 0; j < plantasList.length; j++) {
          const item = plantasList[j];
          if (item.name.substring(0, item.name.indexOf(".")) === elementTrim) {
            match = item;
            if (!mySelectedImages.includes(item)) mySelectedImages.push(item);
            break;
          }
        }
        if (match === undefined) {
          imagesNotfound = `${imagesNotfound}\n• ${elementTrim}`;
        }
        if (showClg) console.log(match);
      }
    }
    //#endregion

    if (imagesNotfound === "" && mySelectedImages.length > 0) {
      if (showClg) console.log(mySelectedImages);
      //setImageList(formatMessage(` • Macro: ${macro.name} `));
      //   • Urbano: ${urbano.name}
      //   • Parqueadero: ${parq.name}
      //   • Torre: ${torre.name}
      //   • Planta: ${plano.name}
      setSelectedImages(mySelectedImages);
      setShowImages(true);
      downloadImages(mySelectedImages);
    } else {
      var mySoporte = "";
      if (imagesNotfound !== "")
        mySoporte = `No se encontraron las siguientes imágenes en BIM 360: ${imagesNotfound}`;
      else if (mySelectedImages.length === 0)
        mySoporte = `La unidad ${myUnit[unitParam]} no tiene imágenes asignadas en la hoja "Plano Tipo" de la ficha técnica.`;
      setWarningUnits("");
      setErrorUnits(formatMessage(mySoporte));
      setSoporteTecnico(mySoporte);
    }
  };
  //#endregion

  //#region 03-C - DOWNLOAD IMAGES
  const downloadImages = async (myImages) => {
    //Validate Token
    const myToken = await isValidToken();

    /* #region  Request Params */
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + myToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    /* #endregion */

    /* #region  DOWNLOAD IMAGE - ASYNC METHOD */
    //console.log( `Selected Project: ${selectedProject.name} | ${selectedProject.id}` );
    setShowImages(false);
    // var tempImages = selectedImages;
    var tempImages = myImages;
    for (let i = 0; i < myImages.length; i++) {
      const myImage = myImages[i];
      var myItemUrl;
      try {
        if (myImage.storageId !== undefined) {
          //#region GENERATE SIGNED S3 URL (NEW API SEP30/2022)
          //Se agrega myStorageId por que Autodesk cambió el API y ahora se debe generar una URL para descargar desde un bucket en AWS S3
          if (myImage.storageId !== undefined) {
            myItemUrl = await Utils_GenerateS3URL(myImage.storageId, myToken);
            if (myItemUrl !== null) {
              if (showClg) console.log(myItemUrl);
              requestOptions = {};
              requestOptions = { method: "GET" };
            } else {
              return;
            }
          }
          //#endregion
        } else {
          //#region GET HREF (OLD API - DEPRICATED SEP30/2022)
          const response = await fetch(
            `https://developer.api.autodesk.com/data/v1/projects/${selectedProject.id}/items/${myImage.id}`,
            requestOptions
          );
          const json = await response.json();
          if (showClg) console.log(response);
          myItemUrl = json.included[0].relationships.storage.meta.link.href;
          if (showClg) console.log(`Href: ${myImage.name} | ${myItemUrl}`);
          //#endregion
        }

        //GET Download Image from URL
        const response2 = await fetch(myItemUrl, requestOptions);
        if (showClg) console.log(response2);

        //BLOB
        const myBlob = await response2.blob();
        if (showClg) console.log(myBlob);

        //URL
        const myUrl = URL.createObjectURL(myBlob);
        if (showClg) console.log(myUrl);

        //SET IMAGE
        setGalleryImage(myUrl);
        tempImages[i].image = myUrl;
        tempImages[i].myBlob = myBlob;
        setSelectedImages(tempImages);
        if (showClg) console.log(tempImages);
      } catch (error) {
        var myError = `Catch Error - Obteniendo Href de imágenes: ${error}`;
        console.error(myError);
        setWarningProyectos(formatMessage(myError));
        setSoporteTecnico(myError);
      }
    }
    setWarningUnits("");
    setGalleryImage("");
    setShowImages(true);
    setShowPdfExportBtn(true);
    /* #endregion */
  };
  //#endregion

  //#endregion ------------------------------------------------------------------------------------------------------------------------------

  //#region UTILS 2 ---------------------------------------------------------------------------------------------------------------------------

  //#region UTILS - ITERATE ELLIPSES
  useEffect(() => {
    if (warningUnits !== "") {
      const interval = setInterval(() => {
        var myEllipsis = warningUnits + ".";
        if (myEllipsis.indexOf("....") > -1) {
          myEllipsis = myEllipsis.substring(0, myEllipsis.length - 4);
        }
        setWarningUnits(myEllipsis);
      }, 500);
      return () => {
        clearInterval(interval);
      };
    }
  }, [warningUnits]);
  //#endregion

  //#region UTILS - GENERATE S3 URL
  async function Utils_GenerateS3URL(myStorageId, myToken) {
    /* #region  Request Params */
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + myToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    /* #endregion */

    //#region GENERATE SIGNED S3 URL
    if (myStorageId !== undefined) {
      try {
        var S3BucketId = myStorageId.substring(
          myStorageId.lastIndexOf(":") + 1,
          myStorageId.indexOf("/") + 1
        );
        var S3ObjectId = myStorageId.substring(
          myStorageId.lastIndexOf("/") + 1
        );
        var s3Endpoint = `https://developer.api.autodesk.com/oss/v2/buckets/${S3BucketId}/objects/${S3ObjectId}/signeds3download`;
        //console.log(s3Endpoint);
        const response = await fetch(`${s3Endpoint}`, requestOptions);
        const json = await response.json();
        //console.log(json.url);
        return json.url;
      } catch (error) {
        console.error(`Catch Error - Obteniendo S3 URL: ${error}`);
        setSoporteTecnico(`Catch Error - Obteniendo S3 URL: ${error}`);
        return null;
      }
    }
    //#endregion
  }
  //#endregion

  //#region GET CONTENTS OF FOLDER --------------------------------------------------------
  const folderContents = async (mySelectedProject, folderId, myToken) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + myToken);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    try {
      const response = await fetch(
        `https://developer.api.autodesk.com/data/v1/projects/${mySelectedProject.id}/folders/${folderId}/contents`, //?filter[attributes.name]=D3-FICHA%20TÉCNICA`,
        requestOptions
      );
      const json = await response.json();
      return json;
    } catch (error) {
      return undefined;
    }
    /* #endregion */
  };
  //#endregion GET CONTENTS OF FOLDER -----------------------------------------------------

  //#endregion ------------------------------------------------------------------------------------------------------------------------------

  //#region 04 - CREATE PDF ----------------------------------------------------------------------------------------------------------------
  const createPdf = async () => {
    setShowLoadingExportBtn(true);
    setShowPdfExportBtn(false);
    const myWidth = 1080;
    const myHeight = 720;

    const doc = new jsPDF({
      orientation: "landscape",
      unit: "px",
      format: [myWidth, myHeight],
    });
    //console.log(doc);

    //Set Date
    let yourDate = new Date();
    const offset = yourDate.getTimezoneOffset();
    yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
    const myDate = yourDate.toISOString().split("T")[0];

    //#region Add Image to 1st Page
    const myFile = new File([selectedImages[0].myBlob], selectedImages[0].name);
    const myImg = await resizeImage(myFile);
    doc.addImage(myImg, "JPEG", 0, 0, myWidth, myHeight, "", "FAST");

    //#region Add Text - CODIGO INMUEBLE
    var codigoInmueble = "";
    const codigoInmuebleTextX = 246;
    const codigoInmuebleTextY = 676;
    if (selectedUnit["CODIGO INMUEBLE"] !== undefined) {
      codigoInmueble = selectedUnit["CODIGO INMUEBLE"];
      doc.setFontSize(25);
      doc.text(codigoInmueble, codigoInmuebleTextX, codigoInmuebleTextY);
    }
    //#endregion

    //#region Add Text - PARQUEADEROS + DEPOSITOS - DEPRECATED
    // var parqueaderos = "";
    // var depositos = "";
    // const boxTextX = 212;
    // const boxTextY = 640;
    // if (
    //   selectedUnit["PARQ TEXT"] !== undefined &&
    //   selectedUnit["PARQ TEXT"] !== ""
    // )
    //   parqueaderos = "Parqueadero(s): \n" + selectedUnit["PARQ TEXT"];
    // if (
    //   selectedUnit["DEPOSITO TEXT"] !== undefined &&
    //   selectedUnit["DEPOSITO TEXT"] !== ""
    // )
    //   depositos = "Depósito: \n" + selectedUnit["DEPOSITO TEXT"];
    // if (parqueaderos !== "" && depositos !== "")
    //   parqueaderos = parqueaderos + "\n\n" + depositos;
    // else if (parqueaderos === "") parqueaderos = depositos;
    // doc.setFontSize(14);
    // doc.text(parqueaderos, boxTextX, boxTextY);
    //#endregion

    //#region Add Text - Fecha
    // const fechaTextX = 410;
    // const fechaTextY = 625;
    doc.setFontSize(18);
    //doc.text(myDate, fechaTextX, fechaTextY); //Cali no pone la fecha de creación por que ya tiene una fecha de la versión de la imagen
    //#endregion

    //#endregion

    //#region Add Pages & Images
    for (let i = 1; i < selectedImages.length; i++) {
      const myFile = new File(
        [selectedImages[i].myBlob],
        selectedImages[i].name
      );
      const myImg = await resizeImage(myFile);
      doc.addPage([myWidth, myHeight], "landscape");
      doc.addImage(myImg, "JPEG", 0, 0, myWidth, myHeight, "", "FAST");
      doc.setFontSize(25);
      doc.text(codigoInmueble, codigoInmuebleTextX, codigoInmuebleTextY);
      doc.setFontSize(14);
      //doc.text(myDate, fechaTextX, fechaTextY); //Cali no pone la fecha de creación por que ya tiene una fecha de la versión de la imagen

      //#region ADD PARQUEADEROS & DEPOSITO IN TEXT BOX
      const myImageName = selectedImages[i].name;
      const parq1 = selectedUnit["PARQUEADERO 1"];
      const parq2 = selectedUnit["PARQUEADERO 2"];
      const deposito = selectedUnit["DEPOSITO"];
      let parq1Text = "";
      let parq2Text = "";
      let depositoText = "";
      // console.log(myImageName);

      if (
        selectedUnit["PARQUEADERO 1 TEXT"] === undefined ||
        selectedUnit["PARQUEADERO 2 TEXT"] === undefined ||
        selectedUnit["DEPOSITO TEXT"] === undefined
      ) {
        if (showClg)
          console.log("La ficha técnica no tiene la configuración v6");
      } else {
        //Parqueadero 1
        if (myImageName.substring(0, myImageName.indexOf(".")) === parq1) {
          // console.log(`${selectedUnit["PARQUEADERO 1 TEXT"]}`);
          parq1Text = "\n" + selectedUnit["PARQUEADERO 1 TEXT"];
        }
        //Parqueadero 2
        if (myImageName.substring(0, myImageName.indexOf(".")) === parq2) {
          // console.log(`${selectedUnit["PARQUEADERO 2 TEXT"]}`);
          parq2Text = "\n" + selectedUnit["PARQUEADERO 2 TEXT"];
        }
        //Depósito
        if (myImageName.substring(0, myImageName.indexOf(".")) === deposito) {
          // console.log(`${selectedUnit["DEPOSITO"]}`);
          depositoText = "DEPÓSITO:\n" + selectedUnit["DEPOSITO TEXT"];
        }

        //Concat Parq 1 & Parq 2
        let parqText = "";
        if (parq1Text !== "" || parq2Text !== "") {
          const myTittle =
            parq1Text !== "" && parq2Text !== ""
              ? "PARQUEADERO(s):"
              : "PARQUEADERO:";
          parqText = `${myTittle}${parq1Text}${parq2Text}`;
          // console.log(parqText);
        }

        //Final Text
        let finalText = "";
        if (parqText !== "") {
          finalText = parqText + "\n" + depositoText;
        } else {
          finalText = depositoText;
        }

        //Add Final Text to Page
        if (finalText !== "") {
          // doc.text(parqueaderos, boxTextX, boxTextY); //DEPRECATED //Esto agregaba los parqueaderos en el footer
          // console.log(finalText);
          const boxText2X = 915;
          const boxText2Y = 95;
          doc.setFontSize(14);
          doc.text(finalText, boxText2X, boxText2Y);
        }
      }
      //#endregion
    }
    //#endregion

    //#region Save PDF
    doc.save(
      `${codigoInmueble} - ${selectedProject.name.substring(
        selectedProject.name.indexOf("-") + 1
      )} - ${myDate}`
    );
    //var blob = doc.output("blob");
    //window.open(URL.createObjectURL(blob));
    //window.open(doc.output("bloburl", "_blank")); //PROBAR CON ESTE EN iOS

    ReactGA.event({
      category: "PDF Download",
      action: selectedProject.name,
      label: selectedUnit[unitParam],
    });
    //#endregion

    //#region ADD AWS METRIC --------------------------------------------------------
    const myMetric = JSON.stringify({
      requestType: "newMetric",
      metric: {
        client: client,
        user: user.userData.email,
        metric: "printPdf",
        value1: selectedProject.name,
        value2: selectedUnit[unitParam],
        sessionTime: TimeMe.getTimeOnCurrentPageInSeconds(),
      },
    });
    if (sendMetric) SendMetric(myMetric);
    TimeMe.stopTimer();
    TimeMe.resetAllRecordedPageTimes();
    TimeMe.startTimer();
    //#endregion ADD AWS METRIC -----------------------------------------------------

    setSendMetric(false);
    setShowLoadingExportBtn(false);
    setShowPdfExportBtn(true);
  };

  const resizeImage = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        4374,
        2915,
        "JPEG",
        40,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });
  //#endregion

  return (
    <Fragment>
      <div /*DEBUG BTN*/ className="debug">
        <button onClick={toggleConsoleLogs}>Debugging</button>
      </div>
      <div
        className="myAppContainer"
        style={{ paddingTop: showImages ? "90px" : "0px" }}
      >
        <div /* DROPDOWN PROYECTOS */ className="dropdownContainer">
          {showDropdownProyectos ? (
            <div name="DropdownProyectos" className="myDropdown">
              <div className="dropdownTittle">Proyecto:</div>
              <Select
                //className="basic-single"
                classNamePrefix="select"
                placeholder="Selecciona un proyecto..."
                //defaultValue={dropdownProyectosOptions[0]}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={true}
                name="Proyectos"
                options={dropdownProyectosOptions}
                styles={dropdownStyle}
                onChange={(value) => {
                  setWarningProyectos("");
                  setShowMultiFichas(false);
                  setShowDropdownUnidades(false);
                  setSelectedFicha("");
                  setSelectedProject({});
                  resetStates();
                  setShowFichaFound(false);
                  if (value == null) console.log("null");
                  else {
                    const index = projectsList.findIndex(
                      (x) => x.name === value.value
                    );
                    var myProject = JSON.stringify(
                      projectsList[index],
                      null,
                      4
                    );
                    if (showClg)
                      console.log(`[${index}] ${value.value} \n ${myProject}`);
                    setSelectedProject(projectsList[index]);
                    bimNavigateToProjectFiles(projectsList[index]);
                  }
                }}
              />
            </div>
          ) : (
            <p style={myStyles.myText}>Cargando Proyectos...</p>
          )}
        </div>
        <div /* WARNING - DROPDOWN PROYECTOS */ className="warningProyectos">
          {/* {formatProyectoMessage()} */}
          {warningProyectos !== "" ? (
            <p style={myStyles.myText}>{warningProyectos}</p>
          ) : null}
        </div>
        <div /*SELECTED FICHA*/ className="dropdownContainer">
          {showFichaFound ? (
            <div className="myDropdown">
              <div className="dropdownTittle">Ficha Técnica:</div>
              <span className="selectedFicha">{selectedFicha}</span>
            </div>
          ) : null}
        </div>
        <div /* DROPDOWN MULTI FICHAS */ className="dropdownContainer">
          {showMultiFichas ? (
            <div name="DropdownMultiFichas" className="myDropdown">
              <div className="dropdownTittle">Ficha técnica:</div>
              <Select
                className="basic-single"
                classNamePrefix="select"
                placeholder="Selecciona una ficha técnica..."
                //defaultValue={dropdownProyectosOptions[0]}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={true}
                name="Fichas Técnicas"
                options={multiFichasOptions}
                styles={dropdownStyle}
                onChange={(value) => {
                  setShowDropdownUnidades(false);
                  resetStates();
                  if (value == null) {
                    console.log("null");
                    setSelectedFicha("");
                    setWarningProyectos(
                      formatMessage("Se encontraron múltiples fichas técnicas.")
                    );
                    setSelectedImages([]);
                  } else {
                    setWarnMultiFichas(
                      formatMessage("Descargando ficha técnica...")
                    );
                    const index = multiFichasOptions.findIndex(
                      (x) => x.value === value.value
                    );
                    var myFicha = JSON.stringify(
                      multiFichasOptions[index],
                      null,
                      4
                    );
                    if (showClg)
                      console.log(`[${index}] ${value.value} \n ${myFicha}`);
                    bimHrefFichaTecnica(
                      selectedProject,
                      multiFichasOptions[index].id,
                      value.value
                    );
                  }
                }}
              />
            </div>
          ) : null}
        </div>
        <div /* WARNING - MULTI FICHAS */ className="warningMultiFichas">
          {warnMultiFichas !== "" ? (
            <p style={myStyles.myText}>{warnMultiFichas}</p>
          ) : null}
        </div>
        <div /* DROPDOWN UNIDADES */ className="dropdownContainer">
          {showDropdownUnidades ? (
            <div name="DropdownUnidades" className="myDropdown">
              <div className="dropdownTittle">Unidad:</div>
              <Select
                className="basic-single"
                classNamePrefix="select"
                placeholder="Selecciona una unidad..."
                //defaultValue={dropdownUnidades[0]}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={true}
                name="Unidades"
                options={dropdownUnidadesOptions}
                styles={dropdownStyle}
                onChange={(value) => {
                  resetStates();
                  if (value == null) {
                    console.log("null");
                  } else {
                    const index = unitsList.findIndex(
                      (x) => x[unitParam] === value.value
                    );
                    if (showClg) {
                      console.log(
                        `Selected Unit: ${value.value}` +
                          "\n" +
                          JSON.stringify(unitsList[index], null, 4)
                      );
                    }
                    selectUnitDropdown(unitsList[index], index);
                  }
                }}
              />
            </div>
          ) : null}
        </div>
        <div /* LISTADO IMAGENES */ className="ImgList">
          {imageList !== "" ? (
            <div style={myStyles.myText2}>{imageList}</div>
          ) : null}
        </div>
        <div /* WARNING UNIDADES */ className="warningUnits">
          {warningUnits !== "" ? (
            <p style={myStyles.myText}>{warningUnits}</p>
          ) : null}
        </div>
        <div /* ERROR UNITS - DROPDOWN UNIDADES */ className="warningUnits">
          {errorUnits !== "" ? (
            <p style={myStyles.myText}>{errorUnits}</p>
          ) : null}
        </div>
        <div /* SOPORTE TECNICO */ className="goToAction">
          {soporteTecnico !== "" ? (
            <button onClick={whatsappClickToChat} className="goToActionBtn">
              <img
                src={waLogo}
                alt="goToActionIcon"
                className="goToActionIcon"
              />
              <p>Solicita aquí soporte técnico</p>
            </button>
          ) : null}
        </div>
        <div /* EXPORT PDF BUTTON */ className="goToAction">
          {showPdfExportBtn ? (
            <button onClick={createPdf} className="goToActionBtn">
              <img
                src={pdfLogo}
                alt="goToActionIcon"
                className="goToActionIcon"
              />
              Guardar PDF
            </button>
          ) : showLoadingExportBtn ? (
            <MyLoading myText="descargando pdf" />
          ) : null}
        </div>
        <div /*SLIDER*/ className="planoTipoSlider">
          {galleryImage !== "" ? (
            <img
              src={galleryImage /*URL.createObjectURL(tempImage)*/}
              alt="galleryImage"
              style={myStyles.myImg}
            ></img>
          ) : null}
        </div>
        <div /* IMAGE GRID */ className="imageGridContainer">
          {showImages ? (
            <div className="imageGrid">
              {selectedImages.map((image) => {
                return (
                  <ImgCard
                    img={image.image}
                    title={image.name}
                    subtitle={image.id}
                    key={image.id}
                  />
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
    </Fragment>
  );
}

export default PlanoTipo;
