import { useState, useEffect, useCallback } from "react";
import "./App.css";
import PlanoTipo from "./PlanoTipo";
import logo1 from "../imgs/LogoCliente.png";
import logo2 from "../imgs/logo-bolivar_scroll.svg";
import logoWorkobot from "../imgs/FavIcon__Workobot_02.png";
import SendMetric from "./SendMetric";
import jwtDecode from "jwt-decode";

function App() {
  const client = "CBC";
  const [userData, setUserData] = useState(undefined);

  // Memoize handleGoogleSessionExpiration to avoid React Hook warnings
  const handleGoogleSessionExpiration = useCallback(() => {
    alert("Your session has expired. Please log in again.");
    handleGoogleLogout();
  }, []);

  // Memoize the silentTokenRefresh function using useCallback
  const silentTokenRefresh = useCallback(() => {
    /* global google */
    google.accounts.id.prompt((notification) => {
      if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
        console.log("Silent refresh failed or user is not signed in.");
        handleGoogleSessionExpiration();
      } else {
        console.log("Token refreshed silently.");
      }
    });
  }, [handleGoogleSessionExpiration]);

  // Check if user data exists in localStorage and handle token expiration
  useEffect(() => {
    const token = localStorage.getItem("googleToken");
    if (token) {
      const isTokenExpired = checkTokenExpiration(token);

      if (!isTokenExpired) {
        const user = jwtDecode(token);
        setUserData(user);
        document.getElementById("homeContainer").style.visibility = "hidden";
      } else {
        // Attempt to silently refresh the token
        silentTokenRefresh();
      }
    }
  }, [silentTokenRefresh]);

  useEffect(() => {
    //console.log(google);
    /* global google */
    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CREDENTIAL_ID,
      callback: handleGoogleSignIn,
    });

    google.accounts.id.renderButton(document.getElementById("googleSignIn"), {
      theme: "outline", //filled_black
      size: "large",
      locale: "es",
      text: "signin_with",
      shape: "rectangular", //pill
      width: 300,
    });

    google.accounts.id.prompt(); // Prompt user to sign in if no session exists
  }, []);

  function handleGoogleSignIn(e) {
    //console.log(e.credential);
    const token = e.credential;
    const user = jwtDecode(token);

    // Save the token in localStorage for session persistence
    localStorage.setItem("googleToken", token);

    setUserData(user);
    document.getElementById("homeContainer").style.visibility = "hidden";

    //#region ADD AWS METRIC
    const myMetric = JSON.stringify({
      requestType: "newMetric",
      metric: {
        client: client,
        user: user.email,
        metric: "login",
        value1: "",
        value2: "",
      },
    });
    SendMetric(myMetric);
    //#endregion
  }

  function handleGoogleLogout() {
    setUserData(undefined);
    localStorage.removeItem("googleToken");
    document.getElementById("homeContainer").style.visibility = "visible";
    google.accounts.id.prompt();
  }

  // Helper function to check if the token is expired
  function checkTokenExpiration(token) {
    const decoded = jwtDecode(token);
    console.log(decoded);
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    return decoded.exp < currentTime; // Returns true if expired
  }

  return (
    <div className="App">
      <div className="background">
        <img src="fondo-proyectos.svg" alt="" />
      </div>
      <div className="clientLogo flex-center">
        <img src={logo2} alt="navBar-Logo" />
      </div>
      <div className="navBar">
        <span className="navBar-Greeting">
          {userData !== undefined
            ? `Hola ${userData.given_name},\nBienvenido a Plano Tipo.`
            : "Bienvenido a Plano Tipo."}
        </span>
      </div>
      {userData && (
        <div className="signout flex-center">
          <button
            id="g_id_signout"
            className="flex-center"
            onClick={handleGoogleLogout}
          >
            <img
              src={userData.picture}
              style={{ height: "42px", borderRadius: "50%" }}
              alt={userData.given_name.substring(0, 1)}
            />
            Cerrar Sesión
          </button>
        </div>
      )}
      {userData && <PlanoTipo userData={userData} />}
      <div id="homeContainer" className="flex-center">
        <img
          className="homeContainer-Logo"
          src={logo1}
          alt="Logo Constructora Bolívar"
        />
        <div className="divider" />
        <div className="homeContainer-description">
          <p style={{ fontSize: "16px", padding: 0 }}>Aplicación Web</p>
          <h1>Plano Tipo</h1>
          <p style={{ fontSize: "16px", padding: 0 }}>
            Herramienta para crear documentos <b>PDF</b> con los planos
            ambientados de una unidad.
          </p>
          <div id="googleSignIn" style={{ padding: "10px 0px" }}></div>
        </div>
      </div>
      <div className="footer flex-center">
        <img
          className="footerIcon"
          src={logoWorkobot}
          alt="Logo Workobot.com"
          style={{ height: "25px" }}
        />
        <span
          style={{
            textAlign: "right",
            fontSize: "12px",
            lineHeight: "12px",
            color: "white",
          }}
        >
          Desarrollado por{" "}
          <a
            href="https://www.workobot.com/"
            target={"_blank"}
            rel="noreferrer"
            style={{ color: "white" }}
          >
            Workobot.com
          </a>
        </span>
      </div>
    </div>
  );
}

export default App;
